<template>
  <div>
    <div class="main_title">
      {{ details_info.apiHelp.name }}
    </div>
    <!-- <div class="video_title">
      视频操作引导教程
    </div>
    <div class="main_video">
      <video
        ref="videoPlayer"
        controls
        src="../assets/111.mp4"
        :poster="posterUrl"
        controlslist="nodownload"
        @play="toPlays"
        @timeupdate="updateTime"
        @pause="toPause"
        :class="[porel, fullwidth, mal, fla, mat, 'movie-show-video']"
        style="width: 100%; height: 100%; object-fit: fill"
      ></video>
    </div> -->
    <div class="main_img">
      <img :src="details_info.helpImage[0].filePath" alt="" />
      <!-- <img src="../assets/1.png" alt="" /> -->
    </div>
  </div>
</template>

<script>
import axios from "../request/index";
export default {
  name: "Home",
  data() {
    return {
      details_info: {}
    };
  },
  methods: {
    getDetails() {
      axios
        .post("api/help/helpDetail?id=" + this.$router.history.current.query.id)
        .then(res => {
          this.details_info = res.data;
        });
    }
  },
  mounted() {},
  created() {
    this.getDetails();
  }
};
</script>

<style>
html,
body {
  background: #f2f2f2;
}
.main_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 31px;
  color: #010101;
  opacity: 1;
  text-align: center;
  margin-top: 16px;
  /* margin-bottom: 32px; */
}
.main_video {
  width: 100%;
  padding: 0px 32px;
  box-sizing: border-box;
}
.video_title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 24px;
  color: #3b3b3b;
  opacity: 1;
  text-align: left;
  padding: 0px 32px;
  box-sizing: border-box;
  margin-bottom: 24px;
}
.main_img {
  width: 100%;
}
.main_img img {
  width: 100%;
}
</style>
